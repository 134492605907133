import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {randomImg} from '../../../Helpers';
import {Subscription} from 'rxjs';
import {AuthUserState} from '../../shared/state/AuthUserState';
import {Store} from '@ngxs/store';
import {hasPermission} from 'jugacu-lib';
import {User} from 'jugacu-lib';
import {Post} from 'jugacu-lib';

@Component({
  selector: 'app-front-post',
  templateUrl: './front-post.component.html',
  styleUrls: ['./front-post.component.scss']
})
export class FrontPostComponent implements OnInit, OnDestroy {

  @Output() deleted: EventEmitter<Post> = new EventEmitter();
  @Input() post: Post;

  private user$: Subscription;
  public user: User;

  constructor(
    private store: Store,
  ) {
    this.user$ = this.store.select(AuthUserState.user).subscribe(
      (user: User) => {
        this.user = user;
      }
    );
  }

  ngOnInit() {
    this.post.contents.forEach((content) => {
      content.rand_outstanding = this.getRandomThumb();
    });

  }

  getRandomThumb(): string {
    return randomImg();
  }

  hasPermission(str: string): boolean {
    return hasPermission(str, this.user);
  }

  ngOnDestroy(): void {
    this.user$.unsubscribe();
  }

  emmit($event: Post) {
    this.deleted.emit($event);
  }
}
