export default class Login {
  static readonly type = '[Auth] Login';

  constructor(
    public payload: {
      authorization: string
    }
  ) {
  }
}
