<div *ngIf="error" class="error">
  <article class="post-container">
    <div class="post-featured">
      <div>
        <div class="blur" style='background-image: url("https://i.imgur.com/lqP7uJ0.gif");'></div>
        <div class="post-featured-image" style='background-image: url("https://i.imgur.com/lqP7uJ0.gif");'></div>
      </div>
    </div>
    <div class="post-data">
      <div class="content">
        <h2>Ups!</h2>
        <p>{{msg}}</p>
      </div>
    </div>
  </article>
</div>
