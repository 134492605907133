import {Pipe, PipeTransform} from '@angular/core';
import mention from 'linkifyjs/plugins/mention';
import * as linkify from 'linkifyjs';
import linkifyStr from 'linkifyjs/string';

@Pipe({
  name: 'parseUrls'
})
export class ParseUrlsPipe implements PipeTransform {

  transform(str: string): any {
    if (!str) {
      return str;
    }

    mention(linkify);

    return linkifyStr(str, {
      className: '',
      target: '_blank',
      formatHref: (href, type) => {
        if (type === 'mention') {
          href = '/profile/' + href.substring(1);
        }
        return href;
      },
    });
  }

}
