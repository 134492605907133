<div class="user-card">
  <div class="blur" [ngStyle]="{'background-image': 'url(' + user.avatar +')'}"></div>
  <div (click)="interact($event)" class="user-avatar" [ngStyle]="{'background-image': 'url(' + user.avatar +')'}">
    <div *ngIf="avatarInteraction === avatarInteractionTypes.UPLOAD_AVATAR">
      <app-loading #loadingComponent [loading]="false"></app-loading>
      <input accept="image/*" type="file" (change)="interact($event)"/>
    </div>
  </div>
  <div class="user-data">
    <div class="user-name"><h2>{{user.name}}</h2></div>
    <div class="user-desc"><h3>{{user.desc}}</h3></div>
  </div>
</div>
