<div class="comment-container">
  <div [ngClass]="{'deleting': loading}" (click)="delete()" *ngIf="(hasPermission('delete own comments') && comment.author.name === user.name) || hasPermission('delete any comment')" class="comment-controls">
    <i class="material-icons">delete</i>
  </div>
  <div class="comment-avatar">
    <div class="blur" [ngStyle]="{'background-image': 'url(' + comment.author.avatar + ')'}"></div>
    <div [routerLink]="['/profile', comment.author.name]" class="comment-avatar-image" [ngStyle]="{'background-image': 'url(' + comment.author.avatar + ')'}"></div>
  </div>
  <div class="comment-data">
    <div class="comment-author">
      {{comment.author.name}}
    </div>
    <div class="comment-comment" [innerHTML]="comment.comment | parseUrls"></div>
  </div>
</div>
