import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  @Input() msg: string;

  public error: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  public isActive() {
    return this.error;
  }

  public enable() {
    this.error = true;
  }

  public disable() {
    this.error = false;
  }

  public toggle() {
    this.error = !this.error;
  }
}
