import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {avatarInteractionTypes} from '../../../interfaces/avatarInteractionTypes';
import {Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {LoadingComponent} from '../loading/loading.component';
import {User} from 'jugacu-lib';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {

  @Input('user') user: User;

  @Input('avatarInteraction') avatarInteraction: avatarInteractionTypes;
  public avatarInteractionTypes = avatarInteractionTypes;

  @ViewChild('loadingComponent') public loadingComponent: LoadingComponent;

  constructor(
    private router: Router,
    private apiService: ApiService
  ) {
  }

  ngOnInit() {

  }

  async interact(event: HTMLInputElement | any) {
    switch (this.avatarInteraction) {
      case avatarInteractionTypes.NONE:
        return;

      case avatarInteractionTypes.UPLOAD_AVATAR:
        if (this.loadingComponent.isActive()) {
          return;
        }

        try {
          this.loadingComponent.enable();
          const image = event.target.files['0'];

          if (image) {
            const response = await this.apiService.uploadToImgur(image);
            this.user.avatar = response.data.link;
          }

        } catch (e) {
          //
        } finally {
          this.loadingComponent.disable();
        }
        break;

      case avatarInteractionTypes.GO_TO_PROFILE:
      default:
        await this.router.navigate(['/profile', this.user.name]);
        break;
    }
  }
}
