import {Component, OnInit, ViewChild} from '@angular/core';
import {LoadingComponent} from '../../components/loading/loading.component';
import {ApiService} from '../../api.service';
import {ErrorComponent} from '../../components/error/error.component';
import {Post} from 'jugacu-lib';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  @ViewChild('loadingComponent', {static: true}) loading: LoadingComponent;
  @ViewChild('errorComponent', {static: true}) error: ErrorComponent;

  public posts: Post[];

  constructor(
    private apiService: ApiService,
  ) {

  }

  async ngOnInit() {

    try {
      const response = await this.apiService.getPosts(0);
      this.posts = response.data;
    } catch (e) {
      this.error.enable();
      console.error(e);
    } finally {
      this.loading.disable();
    }

  }

  async onScroll() {
    try {
      const response = await this.apiService.getPosts(this.posts.length);
      this.posts = this.posts.concat(response.data);
    } catch (e) {
      this.error.enable();
    } finally {
      this.loading.disable();
    }
  }

  postDeleted($event: Post) {
    this.posts.forEach((post, index) => {
      if (post.id === $event.id) {
        this.posts.splice(index, 1);
      }
    });
  }
}
