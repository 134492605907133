import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {AuthService} from '../../auth.service';
import {LoadingComponent} from '../../components/loading/loading.component';
import {ErrorComponent} from '../../components/error/error.component';
import {Store} from '@ngxs/store';
import {AuthState} from '../../shared/state/AuthState';
import {AuthUserState} from '../../shared/state/AuthUserState';
import {randomImg} from '../../../Helpers';
import {AmaiaResponse, Content, hasPermission, Post, User} from 'jugacu-lib';


interface EditorPost extends Omit<Post, 'contents' | 'comments' | 'created_at' | 'updated_at'> {
  contents: EditorErrorContent[];
}

interface EditorErrorContent extends Omit<Content, 'id' | 'desc' | 'type'> {
  id?: number;
  desc?: string;
  type?: 'yt' | 'img' | 'video' | 'text' | 'ph';
  outstanding_error?: boolean;
  desc_error?: boolean;
  delete?: boolean;
  is_uploading?: boolean;
}

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, OnDestroy {

  @ViewChild('loadingComponent', {static: true}) loading: LoadingComponent;
  @ViewChild('errorComponent', {static: true}) error: ErrorComponent;

  private route$: Subscription;
  private editMode = false;

  private user: User;
  private user$: Subscription;

  private post: EditorPost = {
    id: null,
    title: '',
    nsfw: false,
    author: null,
    contents: [],
  };

  private defaultContent: EditorErrorContent = {
    id: null,
    desc: '',
    type: 'img',
    outstanding: 'https://i.imgur.com/lqP7uJ0.gif',
  };

  private errorList: string[] = [];

  private saving: boolean;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private router: Router
  ) {
    this.user$ = this.store.select(AuthUserState.user).subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit() {
    this.loading.disable();

    this.route$ = this.activatedRoute.params.subscribe(async (params) => {
      this.editMode = !!params.id;

      // Checks if its on edit mode.
      if (!this.editMode) {
        this.add();
        return;
      }

      const id = +params.id;
      this.setPost(id);
    });
  }

  async setPost(id: number) {
    try {
      this.loading.enable();
      this.post = (await this.apiService.getPost(id)).data;

      if (this.editMode && !((hasPermission('edit own posts', this.user) && this.post.author.name === this.user.name) || hasPermission('edit any post', this.user))) {
        await this.router.navigate(['/']);
      }

      this.post.contents.forEach((content) => {
        content.rand_outstanding = randomImg();
      });
    } catch (e) {
      this.errorList = [];
      this.errorList.push((e.error && e.error.message) ? e.error.message : 'Server error.');
    } finally {
      this.loading.disable();
    }
  }

  countNotDeleted = (): number => {
    return this.post.contents.filter(content => !content.delete).length;
  }

  add(): void {
    if (this.countNotDeleted() === 50) {
      this.errorList = [];
      this.errorList.push('Has alcanzado el límite');
      return;
    }

    const copy = Object.assign({}, this.defaultContent);
    this.post.contents.push(copy);
  }

  ngOnDestroy(): void {
    this.route$.unsubscribe();
    this.user$.unsubscribe();
  }

  delete(index: number) {
    this.post.contents[index].delete = true;
  }

  change(event: string, content: EditorErrorContent) {

    const imgRegExp = /^(https:)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg)$/;
    const ytRegExp = /^(?:http(?:s)?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user)\/))([^\?&\"'>]+)/;
    const videoRegExp = /^(https:)([/|.|\w|\s|-])*\.(?:mp4|avi|webm)$/;
    const pornhubRegExp = /^(?:https?:\/\/)?(?:www\.)?(?:pornhub.com\/(?:view_video.php\?viewkey=|(embed\/)))([^\?&'>]+)$/;

    content.type = imgRegExp.test(event) ? 'img' : ytRegExp.test(event) ? 'yt' : videoRegExp.test(event) ? 'video' : pornhubRegExp.test(event) ? 'ph' : 'text';
    content.rand_outstanding = randomImg();

    if (content.type === 'ph') {
      this.post.nsfw = true;
    }

    content.outstanding = event;
  }

  copy(content: EditorErrorContent) {
    const virtualEl = document.createElement('textarea');
    virtualEl.value = content.outstanding;

    virtualEl.style.position = 'fixed';
    virtualEl.style.left = '0';
    virtualEl.style.top = '0';
    virtualEl.style.opacity = '0';

    document.body.appendChild(virtualEl);

    virtualEl.focus();
    virtualEl.select();

    document.execCommand('copy');
    document.body.removeChild(virtualEl);
  }

  public async uploadToImgur(event: any, content: EditorErrorContent) {
    const image = event.target.files['0'];
    if (image) {
      try {
        content.is_uploading = true;
        const response = await this.apiService.uploadToImgur(image);

        this.change(response.data.link, content);
      } catch (e) {

      } finally {
        content.is_uploading = false;
      }
    }

  }

  public check(): boolean {
    this.errorList = [];

    if (this.post.title === '') {
      this.errorList.push('El título no puede estar en blanco.');
    }

    let hasPornhub = false;
    this.post.contents.filter(content => !content.delete).forEach((content: EditorErrorContent, index: number) => {
      content.desc_error = false;
      content.outstanding_error = false;

      if (content.type === 'ph') {
        hasPornhub = true;
      }

      if (content.type === 'text' && !content.delete) {
        content.outstanding_error = true;
        this.errorList.push(`El contenido #${index + 1} ha de tener una URL válida.`);
      }

    });

    if (this.countNotDeleted() === 0) {
      this.errorList.push('Has de añadir mínimo un contenido.');
    }

    if (hasPornhub) {
      this.post.nsfw = true;
    }

    return this.errorList.length === 0;
  }

  async create() {
    if (!this.check() || this.saving) {
      return;
    }

    this.saving = true;

    try {
      const post = {
        id: this.post.id,
        title: this.post.title,
        nsfw: this.post.nsfw,
        author: null,
        created_at: null,
        updated_at: null,
        comments: null,
        contents: []
      };

      const token = this.store.selectSnapshot(AuthState.token);

      let response: AmaiaResponse<Post>;

      if (!this.editMode) {
        this.post.contents.forEach(content => {
          if (!content.delete) {
            post.contents.push(content);
          }
        });

        response = await this.authService.createPost(token, post);
      } else {
        this.post.contents.forEach(content => {
          if (content.delete && content.id !== null) {
            // delete a content
            post.contents.push({
              id: content.id,
              delete: content.delete
            });
          } else if (!content.delete && content.id !== null) {
            // edit a content
            post.contents.push({
              id: content.id,
              outstanding: content.outstanding,
              desc: content.desc
            });
          } else if (!content.delete) {
            post.contents.push({
              outstanding: content.outstanding,
              desc: content.desc
            });
          }

        });

        response = await this.authService.updatePost(token, post);
      }

      await this.router.navigate(['/post', response.data.id]);
    } catch (e) {
      this.errorList = [];
      this.errorList.push((e.error && e.error.message) ? e.error.message : 'Server error.');
    } finally {
      this.saving = false;
    }

  }
}
