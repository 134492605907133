<div #wrapper id="wrapper" infiniteScroll
     [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="50"
     [infiniteScrollContainer]="wrapper"
     (scrolled)="onScroll()">
  <app-loading #loadingComponent></app-loading>
  <div class="post-page-title" *ngIf="!loading.isActive()">
    <h2>
      <i class="material-icons">account_circle</i>
      <span>{{this.user}}</span>
    </h2>
  </div>
  <app-error #errorComponent [msg]="'Este usuario no ha publicado nada aún'"></app-error>
  <app-front-post (deleted)="postDeleted($event)" *ngFor="let post of posts" [post]="post"></app-front-post>
</div>
