import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as config from '../config.json';
import SearchResponse from '../interfaces/SearchResponse';
import {Post} from 'jugacu-lib';
import {ImgurResponse} from 'jugacu-lib';
import {AmaiaResponse} from 'jugacu-lib';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public getPosts(offset: number): Promise<AmaiaResponse<Post[]>> {
    return this.httpClient.get<AmaiaResponse<Post[]>>(`${config.AMAIA_API_URL}/posts/offset/${offset}`).toPromise();
  }

  public getPost(id: number): Promise<AmaiaResponse<Post>> {
    return this.httpClient.get<AmaiaResponse<Post>>(`${config.AMAIA_API_URL}/posts/get/${id}`).toPromise();
  }

  public search(query: string): Promise<AmaiaResponse<SearchResponse>> {
    return this.httpClient.post<AmaiaResponse<SearchResponse>>(`${config.AMAIA_API_URL}/search`, {
      query
    }).toPromise();
  }

  async getPostsFrom(user: string, offset: number) {
    return this.httpClient.get<AmaiaResponse<Post[]>>(`${config.AMAIA_API_URL}/posts/get/from/${user}/${offset}`).toPromise();
  }

  async uploadToImgur(file: File): Promise<ImgurResponse> {

    const formData = new FormData();
    formData.append('image', file);

    return this.httpClient.post<ImgurResponse>(`https://api.imgur.com/3/image`, formData, {
      headers: new HttpHeaders({
        Authorization: `Client-ID ${config.IMGUR_AUTH}`,
      })
    }).toPromise();
  }
}
