import {Component, Input, OnInit} from '@angular/core';
import {avatarInteractionTypes} from '../../../interfaces/avatarInteractionTypes';
import {AuthService} from '../../auth.service';
import {Store} from '@ngxs/store';
import {AuthState} from '../../shared/state/AuthState';
import UpdateSettings from '../../shared/auth/UpdateSettings';
import {User} from 'jugacu-lib';

@Component({
  selector: 'app-config-modal',
  templateUrl: './config-modal.component.html',
  styleUrls: ['./config-modal.component.scss']
})
export class ConfigModalComponent implements OnInit {

  @Input('isModalActive') public isModalActive: boolean;

  @Input('user') private user: User;

  private tempUser: User;
  private avatarInteractionTypes = avatarInteractionTypes;

  private submitting: boolean;
  private errors: boolean;
  private errorList: [];

  constructor(
    private authService: AuthService,
    private store: Store
  ) {
  }

  ngOnInit() {

  }

  public enable() {
    this.tempUser = Object.assign({}, this.user);
    this.errors = false;
    this.errorList = [];
    this.isModalActive = true;
  }

  public disable() {
    this.isModalActive = false;
  }

  public async submit() {

    if (this.submitting) {
      return;
    }

    try {
      this.errors = false;
      this.submitting = true;
      const token = this.store.selectSnapshot(AuthState.token);
      await this.store.dispatch(new UpdateSettings(token, this.tempUser)).toPromise();
    } catch (e) {
      this.errors = true;
      this.errorList = e.error.errors;
    } finally {
      this.submitting = false;
      if (!this.errors) {
        this.disable();
      }
    }

  }

}
