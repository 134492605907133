import {Action, Selector, State, StateContext} from '@ngxs/store';
import {AuthService} from '../../auth.service';
import UpdateUser from '../auth/UpdateUser';
import {tap} from 'rxjs/operators';
import ResetUser from '../auth/ResetUser';
import UpdateSettings from '../auth/UpdateSettings';
import {Injectable} from '@angular/core';
import {User} from 'jugacu-lib';

@State<User>({
  name: 'user',
  defaults: {
    name: 'Invitado',
    desc: 'Cuenta limitada',
    avatar: 'https://i.imgur.com/ANQd5cz.gif',
    permissions: null,
    roles: null,
    nsfw: null,
  }
})
@Injectable()
export class AuthUserState {

  constructor(
    private authService: AuthService
  ) {
  }

  @Selector()
  static user(state: User): User {
    return state;
  }

  @Action(UpdateSettings)
  updateSettings(ctx: StateContext<User>, action: UpdateSettings) {
    return this.authService.changeSettings(action.token, action.user).pipe(
      tap((result) => {
        ctx.patchState({
          desc: result.data.desc,
          avatar: result.data.avatar,
          nsfw: result.data.nsfw
        });
      })
    );
  }

  @Action(UpdateUser)
  update(ctx: StateContext<User>, action: UpdateUser) {
    return this.authService.getUser(action.token).pipe(
      tap((result: User) => {
        ctx.patchState({
          name: result.name,
          desc: result.desc,
          avatar: result.avatar,
          roles: result.roles,
          permissions: result.permissions,
          nsfw: result.nsfw
        });
      })
    );
  }

  @Action(ResetUser)
  reset(ctx: StateContext<User>, action: ResetUser) {
    ctx.patchState({
      name: 'Invitado',
      desc: 'Cuenta limitada',
      avatar: 'https://i.imgur.com/ANQd5cz.gif',
      roles: null,
      nsfw: null,
      permissions: null,
    });
  }
}
