<div id="wrapper">
  <div class="post-page-title">
    <h2>Buscador</h2>
  </div>

  <form (ngSubmit)="onSubmit()" id="search-form" [formGroup]="form">
    <input formControlName="query" required autocomplete="off" autocorrect="off" autocapitalize="off"
           spellcheck="false" placeholder="Buscar..." id="search-input" type="text">
  </form>

  <app-loading #loadingComponent></app-loading>
  <app-error #errorComponent [msg]="'Algo no funciona :('"></app-error>

  <div class="post-page-title" *ngIf="users.length > 0">
    <h2>Usuarios</h2>
  </div>
  <app-user-card *ngFor="let user of users" [user]="user"></app-user-card>

  <div class="post-page-title" *ngIf="posts.length > 0">
    <h2>Posts</h2>
  </div>
  <app-front-post (deleted)="postDeleted($event)" *ngFor="let post of posts" [post]="post"></app-front-post>
</div>
