export default class UpdateUser {
  static readonly type = '[User] UpdateUser';

  public constructor(
    public token: string
  ) {
  }


}
