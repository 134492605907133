import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs/internal/Subscription';
import {ApiService} from '../../api.service';
import {LoadingComponent} from '../../components/loading/loading.component';
import {ErrorComponent} from '../../components/error/error.component';
import {randomImg} from '../../../Helpers';
import {AuthUserState} from '../../shared/state/AuthUserState';
import {Store} from '@ngxs/store';
import {hasPermission, Post, User, Comment} from 'jugacu-lib';

@Component({
  selector: 'app-post-page',
  templateUrl: './post-page.component.html',
  styleUrls: ['./post-page.component.scss']
})
export class PostPageComponent implements OnInit, OnDestroy {

  @ViewChild('loadingComponent', {static: true}) loading: LoadingComponent;
  @ViewChild('errorComponent', {static: true}) error: ErrorComponent;

  private post: Post;
  private sub: Subscription;

  private user: User;
  private user$: Subscription;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private store: Store,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(async (params) => {
      const id = +params.id;
      try {
        this.post = (await this.apiService.getPost(id)).data;
        this.post.contents.forEach((content) => {
          content.rand_outstanding = this.getRandomThumb();
        });
      } catch (e) {
        this.error.enable();
        console.error(e);
      } finally {
        this.loading.disable();
      }
    });

    this.user$ = this.store.select(AuthUserState.user).subscribe(
      (val: User) => {
        this.user = val;
      }
    );
  }

  getRandomThumb(): string {
    return randomImg();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  hasPermission(str: string) {
    return hasPermission(str, this.user);
  }

  async postDeleted($event: Post) {
    await this.router.navigate(['/']);
  }

  commentDeleted(index: number) {
    this.post.comments.splice(index, 1);
  }

  createdComment(comment: Comment) {
    this.post.comments.push(comment);
  }
}
