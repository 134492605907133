import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IndexComponent} from './pages/index/index.component';
import {PostPageComponent} from './pages/post-page/post-page.component';
import {SearcherComponent} from './pages/searcher/searcher.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {AuthGuard} from './auth-guard.service';
import {EditorComponent} from './pages/editor/editor.component';
import {CreateGuard} from './create-guard.guard';

const routes: Routes = [
  {path: 'post/:id', component: PostPageComponent},
  {path: 'search', component: SearcherComponent},
  {path: 'profile/:user', component: ProfileComponent},
  {path: 'new', component: EditorComponent, canActivate: [AuthGuard, CreateGuard]},
  {path: 'edit/:id', component: EditorComponent, canActivate: [AuthGuard]},
  {path: '', component: IndexComponent},
  {path: '**', component: IndexComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
