import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ApiService} from '../../api.service';
import {LoadingComponent} from '../../components/loading/loading.component';
import {ErrorComponent} from '../../components/error/error.component';
import {User, Post} from 'jugacu-lib';

@Component({
  selector: 'app-searcher',
  templateUrl: './searcher.component.html',
  styleUrls: ['./searcher.component.scss']
})
export class SearcherComponent implements OnInit {

  @ViewChild('loadingComponent', {static: true}) loading: LoadingComponent;
  @ViewChild('errorComponent', {static: true}) error: ErrorComponent;

  public readonly form: FormGroup;
  public posts: Post[] = [];
  public users: User[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService
  ) {
    this.form = this.formBuilder.group({
      query: ''
    });
  }

  ngOnInit() {
    this.loading.disable();
  }

  async onSubmit() {
    const query = this.form.value.query;

    if (query === '') { return; }

    this.posts = [];
    this.users = [];

    this.error.disable();
    this.loading.enable();

    try {
      const response = await this.apiService.search(query);
      this.posts = response.data.posts;
      this.users = response.data.users;
    } catch (e) {
      this.error.enable();
    } finally {
      this.loading.disable();
    }
  }

  postDeleted($event: Post) {
    this.posts.forEach((post, index) => {
      if (post.id === $event.id) {
        this.posts.splice(index, 1);
      }
    });
  }
}
