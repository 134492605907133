<article class="post-container">
  <div class="post-featured">
    <div [routerLink]="['/profile', post.author.name]" class="author"
         [ngStyle]="{'background-image': 'url(' + post.author.avatar + ')'}"></div>
    <div>
      <div [routerLink]="['/post', post.id]" class="blur"
           [ngStyle]="{'background-image': 'url(' + (post.contents[0].type === 'yt' ? 'https://img.youtube.com/vi/' + (post.contents[0].outstanding | getYTId) + '/0.jpg' : post.contents[0].type === 'video' || post.contents[0].type === 'ph' ? post.contents[0].rand_outstanding : post.contents[0].outstanding) + ')'}"></div>

      <div *ngIf="!post.nsfw || user.nsfw" class="outstanding">
        <div [routerLink]="['/post', post.id]" *ngIf="post.contents[0].type === 'img'" class="post-featured-image"
             [ngStyle]="{'background-image': 'url(' + post.contents[0].outstanding + ')'}"></div>

        <div *ngIf="post.contents[0].type === 'video'" class="post-featured-video">
          <video controls="controls" [src]="post.contents[0].outstanding"></video>
        </div>

        <div [routerLink]="['/post', post.id]" *ngIf="post.contents[0].type === 'yt'" class="post-featured-video">
          <iframe width="100%" height="300"
                  [src]="'https://www.youtube.com/embed/' + (post.contents[0].outstanding | getYTId) + '?modestbranding=1&showinfo=0&rel=0' | bypassSecurityTrustResourceUrl"
                  frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"></iframe>
        </div>
        <div [routerLink]="['/post', post.id]" *ngIf="post.contents[0].type === 'ph'" class="post-featured-video">
          <iframe width="100%" height="300"
                  [src]="'https://www.pornhub.com/embed/' + (post.contents[0].outstanding | getPHId) | bypassSecurityTrustResourceUrl"
                  frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"></iframe>
        </div>
      </div>

      <div [routerLink]="['/post', post.id]" class="nsfw" *ngIf="post.nsfw && !user.nsfw">
        <div class="nsfw-alert"><i class="material-icons">warning</i>
          <h2>NSFW</h2>
          <h3>Haz click para ver este contenido</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="post-data">
    <div class="content">
      <h2 [routerLink]="['/post', post.id]">{{post.title}}</h2>
      <p appMention [innerHTML]="post.contents[0].desc | parseUrls"></p>
    </div>
    <div class="controllers">
      <div class="icon" [routerLink]="['/post', post.id]">
        <i class="material-icons">more</i>
        <span>{{post.contents.length}}</span>
      </div>
      <div class="icon" [routerLink]="['/post', post.id]">
        <i class="material-icons">comment</i>
        <span>{{post.comments.length}}</span>
      </div>
      <app-post-edit-delete (deleted)="emmit($event)" [user]="user" [post]="post"></app-post-edit-delete>
    </div>
  </div>
</article>
