<div id="wrapper">
  <app-loading #loadingComponent></app-loading>
  <app-error #errorComponent [msg]="'El post que buscabas no ha sido encontrado :(\n'"></app-error>

  <div *ngIf="!loading.isActive() && !error.isActive()">
    <div class="post-page-title">
      <h2>{{post.title}}</h2>
      <app-post-edit-delete (deleted)="postDeleted($event)" [user]="user" [post]="post"></app-post-edit-delete>
    </div>
    <article class="post-container" *ngFor="let content of post.contents">
      <div class="post-featured">
        <div [routerLink]="['/profile', post.author.name]" class="author"
             [ngStyle]="{'background-image': 'url(' + post.author.avatar + ')'}"></div>
        <div>
          <div [routerLink]="['/post', post.id]" class="blur"
               [ngStyle]="{'background-image': 'url(' + (content.type === 'yt' ? 'https://img.youtube.com/vi/' + (content.outstanding | getYTId) + '/0.jpg' : content.type === 'video' || content.type === 'ph' ? content.rand_outstanding : content.outstanding) + ')'}"></div>

          <div [routerLink]="['/post', post.id]" *ngIf="content.type === 'img'" class="post-featured-image"
               [ngStyle]="{'background-image': 'url(' + content.outstanding + ')'}"></div>

          <div [routerLink]="['/post', post.id]" *ngIf="content.type === 'video'" class="post-featured-video">
            <video controls="controls" [src]="content.outstanding"></video>
          </div>

          <div [routerLink]="['/post', post.id]" *ngIf="content.type === 'yt'" class="post-featured-video">
            <iframe width="100%" height="300"
                    [src]="'https://www.youtube.com/embed/' + (content.outstanding | getYTId) + '?modestbranding=1&showinfo=0&rel=0' | bypassSecurityTrustResourceUrl"
                    frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"></iframe>
          </div>
          <div [routerLink]="['/post', post.id]" *ngIf="content.type === 'ph'" class="post-featured-video">
            <iframe width="100%" height="300"
                    [src]="'https://www.pornhub.com/embed/' + (content.outstanding | getPHId) | bypassSecurityTrustResourceUrl"
                    frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"></iframe>
          </div>
        </div>
      </div>
      <div class="post-data">
        <div class="content">
          <p appMention [innerHTML]="content.desc | parseUrls"></p>
        </div>
      </div>
    </article>
    <div class="post-page-title">
      <h2>Comentarios</h2>
    </div>
    <app-create-comment (created)="createdComment($event)" *ngIf="hasPermission('create comments')" [user]="user" [post]="post"></app-create-comment>
    <app-comment *ngFor="let comment of post.comments; index as i" (deleted)="commentDeleted(i)" [comment]="comment" [user]="user"></app-comment>
  </div>
</div>
