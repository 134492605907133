import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoadingComponent} from '../../components/loading/loading.component';
import {ErrorComponent} from '../../components/error/error.component';
import {ApiService} from '../../api.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {Post} from 'jugacu-lib';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {


  @ViewChild('loadingComponent', {static: true}) loading: LoadingComponent;
  @ViewChild('errorComponent') error: ErrorComponent;

  public posts: Post[] = [];
  private sub: Subscription;
  private user: string;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute
  ) {

  }

  async ngOnInit() {
    this.sub = this.route.params.subscribe(async (params) => {
      this.user = params.user;
      this.posts = [];
      this.loading.enable();
      try {
        const response = await this.apiService.getPostsFrom(this.user, this.posts.length);

        if (!response.data) {
          throw new Error(response.message);
        }

        this.posts = response.data;
        this.error.disable();
      } catch (e) {
        this.error.enable();
      } finally {
        this.loading.disable();
      }

      if (this.posts.length === 0) {
        this.error.enable();
      }
    });

  }

  async onScroll() {
    try {
      const response = await this.apiService.getPostsFrom(this.user, this.posts.length);
      if (response.data) {
        this.posts = this.posts.concat(response.data);
      }
      this.error.disable();
    } catch (e) {
      this.error.enable();
    } finally {
      this.loading.disable();
    }
  }

  postDeleted($event: Post) {
    this.posts.forEach((post, index) => {
      if (post.id === $event.id) {
        this.posts.splice(index, 1);
      }
    });

    if (this.posts.length === 0) {
      this.error.enable();
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
