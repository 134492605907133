import {Directive, HostListener} from '@angular/core';
import {Router} from '@angular/router';

@Directive({
  selector: '[appMention]'
})
export class MentionDirective {

  constructor(
    private router: Router
  ) {
  }

  @HostListener('click', ['$event'])
  public async onClick(e) {
    const href = e.target.getAttribute('href');
    if (e.target.tagName === 'A' && href) {
      if (this.isMention(href)) {
        e.preventDefault();
        await this.router.navigate([this.getPathFromURL(href)]);
      }
    }
  }

  public getPathFromURL(url: string) {
    const el = document.createElement('a');
    el.href = url;
    return el.pathname;
  }

  public isMention(uri: string) {
    return uri && uri.startsWith('/profile/');
  }

}
