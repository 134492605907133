import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {hasPermission} from 'jugacu-lib';
import {Post} from 'jugacu-lib';
import {User} from 'jugacu-lib';

@Component({
  selector: 'app-post-edit-delete',
  templateUrl: './post-edit-delete.component.html',
  styleUrls: ['./post-edit-delete.component.scss']
})
export class PostEditDeleteComponent implements OnInit {

  @Output() deleted: EventEmitter<Post> = new EventEmitter();

  @Input('user') public user: User;
  @Input('post') public post: Post;

  constructor() {
  }

  emmit($event: Post) {
    this.deleted.emit($event);
  }

  ngOnInit(): void {
  }

  hasPermission(str: string): boolean {
    return hasPermission(str, this.user);
  }

}
