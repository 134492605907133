<app-delete-modal (deleted)="emmit($event)" #deleteModalComponent></app-delete-modal>

<div class="controls">
  <div [routerLink]="['/edit', post.id]"
       *ngIf="(hasPermission('edit own posts') && post.author.name === user.name) || hasPermission('edit any post')">
    <i class="material-icons">edit</i>
  </div>
  <div (click)="deleteModalComponent.delete(post, user)" class="no-margin"
       *ngIf="(hasPermission('delete own posts') && post.author.name === user.name) || hasPermission('delete any post')">
    <i class="material-icons">delete</i>
  </div>
</div>
