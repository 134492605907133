import {Action, Selector, State, StateContext} from '@ngxs/store';
import Login from '../auth/Login';
import {tap} from 'rxjs/operators';
import Logout from '../auth/Logout';
import {AuthService} from '../../auth.service';
import Refresh from '../auth/Refresh';
import {Injectable} from '@angular/core';
import {AuthStateModel} from 'jugacu-lib';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    refreshToken: null,
    expires_in: null,
    granted_at: null
  }
})
@Injectable()
export class AuthState {

  constructor(
    private authService: AuthService
  ) {
  }

  @Selector()
  static refreshToken(state: AuthStateModel): string | null {
    return state.refreshToken;
  }

  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return (!!state.token && state.granted_at + state.expires_in > Date.now() / 1000);
  }

  @Selector()
  static isExpired(state: AuthStateModel): boolean {
    return (state.granted_at + state.expires_in < Date.now() / 1000);
  }

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    return this.authService.login(action.payload).pipe(
      tap((result: { access_token: string, refresh_token: string, expires_in: number }) => {
        ctx.patchState({
          token: result.access_token,
          refreshToken: result.refresh_token,
          expires_in: result.expires_in,
          granted_at: Date.now() / 1000
        });
      })
    );
  }

  @Action(Refresh)
  refresh(ctx: StateContext<AuthStateModel>, action: Refresh) {
    return this.authService.refresh(action.payload).pipe(
      tap((result: { access_token: string, refresh_token: string, expires_in: number }) => {
        ctx.patchState({
          token: result.access_token,
          refreshToken: result.refresh_token,
          expires_in: result.expires_in,
          granted_at: Date.now() / 1000
        });
      })
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      token: null,
      refreshToken: null,
      expires_in: null,
      granted_at: null,
    });
    return this.authService.logout(state.token);
  }
}
