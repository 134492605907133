<app-config-modal #configModalComponent [user]="user"></app-config-modal>
<div class="banned" *ngIf="hasRole('banned')">
  Tu cuenta está restringida.
</div>
<div id="app">
  <div *ngIf="authenticating" class="authentication" (click)="authenticating = (!authenticatingError)">
    <div class="modal">
      <h2 *ngIf="!authenticatingError">Verificando</h2>
      <app-loading *ngIf="!authenticatingError" size="small"></app-loading>
      <h3 *ngIf="authenticatingError">
        Error al verificar el inicio de sesión.
      </h3>
    </div>
  </div>
  <main>
    <div id="page-title">
      <div *ngIf="authenticated && hasPermission('create posts')" [routerLink]="['new']" id="add-post"><i class="material-icons">add</i></div>
      <h1 [routerLink]="['/']">i don't care</h1>
    </div>
    <div class="router">
      <router-outlet></router-outlet>
    </div>
  </main>
  <aside>
    <app-user-card id="user" [user]="user"
                   [avatarInteraction]="(authenticated ? avatarInteractionTypes.GO_TO_PROFILE: avatarInteractionTypes.NONE)"></app-user-card>
    <div id="user-menu">
      <div [routerLink]="['/']" [routerLinkActive]="['active']"
           [routerLinkActiveOptions]="{exact:true}" class="user-menu-btn">
        <i class="material-icons">home</i> <span class="menuListTitle">Inicio</span>
      </div>
      <div *ngIf="authenticated" [routerLink]="['profile', user.name]" [routerLinkActive]="['active']"
           [routerLinkActiveOptions]="{exact:true}" class="user-menu-btn">
        <i class="material-icons">account_circle</i> <span class="menuListTitle">Perfil</span>
      </div>
      <div [routerLink]="['search']" [routerLinkActive]="['active']"
           [routerLinkActiveOptions]="{exact:true}" class="user-menu-btn">
        <i class="material-icons">search</i> <span class="menuListTitle">Buscador</span>
      </div>
      <div (click)="configModalComponent.enable()" *ngIf="authenticated" class="user-menu-btn">
        <i class="material-icons">settings</i> <span class="menuListTitle">Configuración</span>
      </div>
      <div *ngIf="!authenticated" (click)="login()" class="user-menu-btn">
        <i class="material-icons">forward</i> <span class="menuListTitle">Inicia sesión</span>
      </div>
      <div *ngIf="authenticated" (click)="logout()" class="user-menu-btn">
        <i class="material-icons">close</i> <span class="menuListTitle">Cerrar sesión</span>
      </div>
    </div>
  </aside>
</div>
