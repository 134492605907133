import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {hasPermission} from 'jugacu-lib';
import {AuthService} from '../../auth.service';
import {Store} from '@ngxs/store';
import {AuthState} from '../../shared/state/AuthState';
import {User} from 'jugacu-lib';
import {Comment} from 'jugacu-lib';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

  @Input() comment: Comment;
  @Input() user: User;
  @Output() deleted: EventEmitter<Comment> = new EventEmitter();

  loading: boolean;

  constructor(
    private authService: AuthService,
    private store: Store
  ) {
  }

  ngOnInit() {
  }

  hasPermission(str: string) {
    return hasPermission(str, this.user);
  }

  async delete() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    try {
      const token = this.store.selectSnapshot(AuthState.token);
      await this.authService.deleteComment(token, this.comment);
      this.deleted.emit(this.comment);
    } catch (e) {

    } finally {
      this.loading = false;
    }
  }
}
