import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {IndexComponent} from './pages/index/index.component';
import {LoadingComponent} from './components/loading/loading.component';
import {HttpClientModule} from '@angular/common/http';
import {ParseUrlsPipe} from './parse-urls.pipe';
import {BypassSecurityTrustResourceUrlPipe} from './bypass-security-trust-resource-url.pipe';
import {FrontPostComponent} from './components/front-post/front-post.component';
import {ErrorComponent} from './components/error/error.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {PostPageComponent} from './pages/post-page/post-page.component';
import {CommentComponent} from './components/comment/comment.component';
import {SearcherComponent} from './pages/searcher/searcher.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserCardComponent} from './components/user-card/user-card.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {NgxsModule} from '@ngxs/store';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {AuthState} from './shared/state/AuthState';
import {AuthUserState} from './shared/state/AuthUserState';
import {ConfigModalComponent} from './components/config-modal/config-modal.component';
import {EditorComponent} from './pages/editor/editor.component';
import {GetYTIdPipe} from './get-ytid.pipe';
import {AutosizeModule} from 'ngx-autosize';
import {DeleteModalComponent} from './components/delete-modal/delete-modal.component';
import {PostEditDeleteComponent} from './components/post-edit-delete/post-edit-delete.component';
import {CreateCommentComponent} from './components/create-comment/create-comment.component';
import {MentionDirective} from './mention.directive';
import { GetPHIdPipe } from './get-phid.pipe';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    LoadingComponent,
    ParseUrlsPipe,
    BypassSecurityTrustResourceUrlPipe,
    FrontPostComponent,
    ErrorComponent,
    PostPageComponent,
    CommentComponent,
    SearcherComponent,
    UserCardComponent,
    ProfileComponent,
    ConfigModalComponent,
    EditorComponent,
    GetYTIdPipe,
    DeleteModalComponent,
    PostEditDeleteComponent,
    CreateCommentComponent,
    MentionDirective,
    GetPHIdPipe,
  ],
  imports: [
    NgxsModule.forRoot([AuthState, AuthUserState]), NgxsStoragePluginModule.forRoot(
      {
        key: 'auth'
      }
    ),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    InfiniteScrollModule,
    FormsModule, ReactiveFormsModule,
    AutosizeModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule {
}
