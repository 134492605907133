<div class="wrapper" *ngIf="isModalActive">
  <div class="modal">
    <div (click)="disable()" id="close-modal"><i class="material-icons">close</i></div>
    <h2>Configuración</h2>
    <hr>
    <app-user-card id="user" [user]="tempUser" [avatarInteraction]="avatarInteractionTypes.UPLOAD_AVATAR"></app-user-card>
    <input [ngModel]="tempUser.nsfw" (ngModelChange)="tempUser.nsfw = $event" type="checkbox" id="switch-conf">
    <label for="switch-conf">
      <div>NSFW</div>
    </label>
    <div class="flex">
      <input placeholder="Descripción" minlength="1" maxlength="50" autocomplete="off" autocorrect="off"
             autocapitalize="off"
             spellcheck="false"
             type="text" [ngModel]="tempUser.desc" (ngModelChange)="tempUser.desc = $event">
      <button [ngClass]="{'error': errors, 'sending': submitting}"
              (click)="submit()">{{errors ? 'Error' : submitting ? 'Guardando..' : 'Guardar'}}</button>
    </div>
    <p *ngFor="let error of errorList">
      {{error}}
    </p>
  </div>
</div>
