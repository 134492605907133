import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Store} from '@ngxs/store';
import {AuthService} from '../../auth.service';
import {AuthState} from '../../shared/state/AuthState';
import {LoadingComponent} from '../loading/loading.component';
import {User} from 'jugacu-lib';
import {Post} from 'jugacu-lib';
import {Comment} from 'jugacu-lib';

@Component({
  selector: 'app-create-comment',
  templateUrl: './create-comment.component.html',
  styleUrls: ['../comment/comment.component.scss']
})
export class CreateCommentComponent implements OnInit {

  @Input() user: User;
  @Input() post: Post;

  @ViewChild('loadingComponent') loading: LoadingComponent;

  @Output() created: EventEmitter<Comment> = new EventEmitter();

  error: string;
  comment: string;

  constructor(
    private store: Store,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
  }

  async submit(): Promise<void> {
    if (this.loading.isActive() || this.comment.length === 0) {
      return;
    }

    try {
      this.error = '';
      this.loading.enable();
      const token = this.store.selectSnapshot(AuthState.token);
      const response = await this.authService.createComment(token, this.post, this.comment);
      this.comment = '';
      this.created.emit(response.data);
    } catch (e) {
      this.error = (e.error && e.error.message) ? e.error.message : 'Server error.';
    } finally {
      this.loading.disable();
    }
  }

}
