export default class Refresh {
  static readonly type = '[Auth] Refresh';

  constructor(
    public payload: {
      refreshToken: string
    }
  ) {
  }
}
