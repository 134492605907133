import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPHId'
})
export class GetPHIdPipe implements PipeTransform {

  transform(str: string): string {
    const phRegExp = /(?:https?:\/\/)?(?:www\.)?(?:pornhub.com\/(?:view_video.php\?viewkey=|(?:embed\/)))([^\?&'>]+)/;
    return  str.match(phRegExp)[1];
  }

}
