export const randomImg = (): string => {
  const images = [
    'https://i.imgur.com/p3oOyjX.png',
    'https://i.imgur.com/nIyEULj.gif',
    'https://i.imgur.com/xyzN0k5.jpg',
    'https://i.imgur.com/0pqPWIn.jpg',
    'https://i.imgur.com/8oJHFt4.jpg',
    'https://i.imgur.com/x1Vaz9L.jpg'
  ];
  return images[Math.floor(Math.random() * images.length)];
};
