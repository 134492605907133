<div id="wrapper">
  <app-loading #loadingComponent></app-loading>
  <app-error #errorComponent [msg]="'El post que buscabas no ha sido encontrado :(\n'"></app-error>

  <div *ngIf="!loading.isActive() && !error.isActive()">
    <div class="post-page-title iconized">
      <h3><i class="material-icons">add_to_photos</i><span>Nuevo post</span></h3>
    </div>
    <div class="post-page-title">
      <input [ngModel]="post.title" (ngModelChange)="post.title = $event" autocapitalize="off" autocomplete="off"
             autocorrect="off" maxlength="50" minlength="1" placeholder="Título" spellcheck="false" type="text">
    </div>
    <div *ngFor="let content of post.contents; index as i">
      <article *ngIf="!content.delete" class="post-container">
        <div class="post-featured">
          <div (click)="delete(i)" class="delete">
            <i class="material-icons">delete</i>
          </div>
          <div class="author"
               [ngStyle]="{'background-image': 'url(' + (post.author ? post.author.avatar : user.avatar) + ')'}"></div>
          <div>
            <div class="blur"
                 [ngStyle]="{'background-image': 'url(' + (content.type === 'yt' ? 'https://img.youtube.com/vi/' + (content.outstanding | getYTId) + '/0.jpg' : content.type === 'video' || content.type === 'ph' ? content.rand_outstanding : content.outstanding) + ')'}"></div>

            <div *ngIf="content.type === 'img'" class="post-featured-image"
                 [ngStyle]="{'background-image': 'url(' + content.outstanding + ')'}"></div>

            <div *ngIf="content.type === 'video'" class="post-featured-video">
              <video controls="controls" [src]="content.outstanding"></video>
            </div>

            <div *ngIf="content.type === 'yt'" class="post-featured-video">
              <iframe width="100%" height="300"
                      [src]="'https://www.youtube.com/embed/' + (content.outstanding | getYTId) + '?modestbranding=1&showinfo=0&rel=0' | bypassSecurityTrustResourceUrl"
                      frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"></iframe>
            </div>
            <div *ngIf="content.type === 'ph'" class="post-featured-video">
              <iframe width="100%" height="300"
                      [src]="'https://www.pornhub.com/embed/' + (content.outstanding | getPHId) | bypassSecurityTrustResourceUrl"
                      frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"></iframe>
            </div>
          </div>
        </div>
        <div class="post-data">
          <div class="content">
            <div class="input">
              <i (click)="copy(content)" title="Copy link to clipboard" class="material-icons clipboard">link</i>
              <input maxlength="255" autocapitalize="off" [ngClass]="{error: content.outstanding_error}"
                     autocomplete="off"
                     spellcheck="false" [ngModel]="content.outstanding"
                     (ngModelChange)="change($event, content)" type="text" placeholder="URL">
              <div class="upload">
                <input [disabled]="content.is_uploading" accept="image/*" type="file"
                       (change)="uploadToImgur($event, content)">
                <i [ngClass]="{uploading: content.is_uploading}" class="material-icons">
                  add_photo_alternate
                </i>
              </div>
            </div>
            <textarea autosize [ngClass]="{error: content.desc_error}" rows="1" [ngModel]="content.desc"
                      (ngModelChange)="content.desc = $event"
                      placeholder="Descripción"></textarea>
          </div>
        </div>
      </article>
    </div>
    <div class="btn" (click)="add()">
      <i class="material-icons">add_circle</i>
    </div>
    <div *ngIf="errorList.length > 0" class="errors">
      <i class="material-icons">warning</i>
      <div class="message">
        <ul>
          <li *ngFor="let error of errorList">
            {{error}}
          </li>
        </ul>
      </div>
    </div>
    <div class="flex">
      <div class="nsfw">
        <input [ngModel]="post.nsfw" (ngModelChange)="post.nsfw = $event" type="checkbox" id="switch-conf">
        <label for="switch-conf">
          <div>NSFW</div>
        </label>
      </div>
      <div class="publish">
        <div (click)="create()" class="btn">
          {{saving ? 'Guardando..' : 'Publicar'}}
        </div>
      </div>
    </div>
  </div>
</div>
