import {Component, OnDestroy, ViewChild} from '@angular/core';
import * as config from '../config.json';
import {AuthState} from './shared/state/AuthState';
import Login from './shared/auth/Login';
import {Actions, ofActionDispatched, Store} from '@ngxs/store';
import { Subscription} from 'rxjs';
import Logout from './shared/auth/Logout';
import Refresh from './shared/auth/Refresh';
import {AuthUserState} from './shared/state/AuthUserState';
import UpdateUser from './shared/auth/UpdateUser';
import ResetUser from './shared/auth/ResetUser';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {ConfigModalComponent} from './components/config-modal/config-modal.component';
import {avatarInteractionTypes} from '../interfaces/avatarInteractionTypes';
import {hasPermission, hasRole} from 'jugacu-lib';
import {User} from 'jugacu-lib';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnDestroy {
  title = 'I don\'t care';

  @ViewChild('configModalComponent') configModalComponent: ConfigModalComponent;

  private authenticated$: Subscription;
  public authenticated: boolean;

  public authenticating: boolean;
  private authenticatingError = false;

  private user$: Subscription;
  public user: User;

  private route$: Subscription;
  private actions$: Subscription;

  public avatarInteractionTypes = avatarInteractionTypes;

  constructor(
    private store: Store,
    private router: Router,
    private actions: Actions
  ) {

    this.actions$ = this.actions.pipe(ofActionDispatched(Logout)).subscribe(async () => {
      await this.router.navigate(['/']);
    });


    this.authenticated$ = this.store.select(AuthState.isAuthenticated).subscribe(
      async (val: boolean) => {
        this.authenticated = val;

        if (this.authenticated) {
          try {
            await this.store.dispatch(new UpdateUser(this.store.selectSnapshot(AuthState.token))).toPromise();
          } catch (e) {
            this.logout();
          }
        }

      }
    );

    this.user$ = this.store.select(AuthUserState.user).subscribe(
      (val: User) => {
        this.user = val;
      }
    );

    this.route$ = this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(
      async (val) => {
        await this.checkToken();
      }
    );
  }

  login(): void {
    // tslint:disable-next-line:max-line-length
    window.location.href = `${config.AMAIA_BASE_URL}/oauth/authorize?client_id=${config.AMAIA_CLIENT_ID}&redirect_uri=${config.AMAIA_CALLBACK}&response_type=${config.AMAIA_RESPONSE_TYPE}&scope=${config.AMAIA_SCOPE}`;
  }

  async logout() {
    try {
      await this.store.dispatch(new Logout()).toPromise();
    } catch (e) {
      //
    } finally {
      this.store.dispatch(new ResetUser());
    }
  }

  ngOnDestroy(): void {
    this.authenticated$.unsubscribe();
    this.user$.unsubscribe();
    this.route$.unsubscribe();
    this.actions$.unsubscribe();
  }

  async checkToken(): Promise<void> {

    if (this.authenticating) {
      return;
    }

    if (this.store.selectSnapshot(AuthState.isExpired) && this.store.selectSnapshot(AuthState.refreshToken)) {
      try {
        this.authenticating = true;
        await this.store.dispatch(new Refresh({refreshToken: this.store.selectSnapshot(AuthState.refreshToken)})).toPromise();
        this.authenticating = false;
      } catch (e) {
        try {
          await this.logout();
        } catch (e) {
          //
        }
        this.authenticatingError = true;
      }
    }

    const authorization = window.location.search.slice(1).split('&')[0].split('=')[1];

    if (authorization && !this.authenticated) {
      try {
        this.authenticating = true;
        await this.store.dispatch(new Login({authorization})).toPromise();
        this.authenticating = false;
      } catch (e) {
        try {
          await this.logout();
        } catch (e) {
          //
        }
        this.authenticatingError = true;
      } finally {
        window.history.replaceState({}, document.title, '/');
      }
    }
  }

  hasPermission(str: string) {
    return hasPermission(str, this.user);
  }

  hasRole(str: string) {
    return hasRole(str, this.user);
  }
}
