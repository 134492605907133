<div class="comment-container">
  <div class="comment-avatar">
    <div class="blur" [ngStyle]="{'background-image': 'url(' + user.avatar + ')'}"></div>
    <div [routerLink]="['/profile', user.name]" class="comment-avatar-image" [ngStyle]="{'background-image': 'url(' + user.avatar + ')'}"></div>
  </div>
  <div class="comment-data">
    <app-loading #loadingComponent size="small" [loading]="false"></app-loading>
    <div class="error" (click)="error = ''" *ngIf="error">
      {{error}}
    </div>
    <div *ngIf="!loadingComponent.isActive() && !error">
      <div class="comment-author">
        {{user.name}}
      </div>
      <textarea maxlength="280" [ngModel]="comment" (ngModelChange)="comment = $event" class="comment-textarea" placeholder="Escribe algo.."></textarea>
      <button (click)="submit()" type="submit" class="comment-submit">Publicar</button>
    </div>
  </div>
</div>
