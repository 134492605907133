import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getYTId'
})
export class GetYTIdPipe implements PipeTransform {

  transform(str: string): string {
    const ytRegExp = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/;
    return  str.match(ytRegExp)[1];
  }

}
