import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as config from '../config.json';
import {User} from 'jugacu-lib';
import {Post} from 'jugacu-lib';
import {Comment} from 'jugacu-lib';
import {AmaiaResponse} from 'jugacu-lib';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  login(payload: { authorization: string }): Observable<any> {

    return this.httpClient.post(`${config.SECRET_HANDLER}`, {
      authorization: payload.authorization
    });

  }

  refresh(payload: { refreshToken: string }): Observable<any> {

    return this.httpClient.post(`${config.SECRET_HANDLER_REFRESH}`, {
      refresh: payload.refreshToken
    });

  }

  logout(token: string): Observable<any> {
    return this.httpClient.get(`${config.AMAIA_BASE_URL}/api/user/logout`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      })
    });
  }

  getUser(token: string): Observable<any> {
    return this.httpClient.get(`${config.AMAIA_BASE_URL}/api/user`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      })
    });
  }

  changeSettings(token: string, user: User): Observable<AmaiaResponse<User>> {
    return this.httpClient.post<AmaiaResponse<User>>(`${config.AMAIA_BASE_URL}/api/user/settings`, {
      avatar: user.avatar,
      desc: user.desc,
      nsfw: user.nsfw
    }, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      })
    });
  }

  createPost(token: string, post: Post): Promise<AmaiaResponse<Post>> {
    return this.httpClient.post<AmaiaResponse<Post>>(`${config.AMAIA_API_URL}/posts/create`, post, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      })
    }).toPromise();
  }

  updatePost(token: string, post: Post): Promise<AmaiaResponse<Post>> {
    return this.httpClient.put<AmaiaResponse<Post>>(`${config.AMAIA_API_URL}/posts/update/${post.id}`, post, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      })
    }).toPromise();
  }

  delete(token: string, post: Post): Promise<AmaiaResponse<Post>> {
    return this.httpClient.delete<AmaiaResponse<Post>>(`${config.AMAIA_API_URL}/posts/delete/${post.id}`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      })
    }).toPromise();
  }

  createComment(token: string, post: Post, comment: string): Promise<AmaiaResponse<Comment>> {
    return this.httpClient.post<AmaiaResponse<Comment>>(`${config.AMAIA_API_URL}/posts/get/${post.id}/comments/create`, {
      comment
    }, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      })
    }).toPromise();
  }

  async deleteComment(token: string, comment: Comment): Promise<AmaiaResponse<Comment>> {
    return this.httpClient.delete<AmaiaResponse<Comment>>(`${config.AMAIA_API_URL}/posts/comments/delete/${comment.id}`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      })
    }).toPromise();
  }
}
