import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @Input() size: 'small' | null;
  @Input() loading: boolean;

  @ViewChild('loadingRef') public loadingRef: ElementRef;

  constructor() {
  }

  ngOnInit() {
    if (typeof this.loading === 'undefined') {
      this.loading = true;
    }
  }

  public isActive() {
    return this.loading;
  }

  public enable() {
    this.loading = true;
  }

  public disable() {
    this.loading = false;
  }

  public toggle() {
    this.loading = !this.loading;
  }

}
