import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {AuthUserState} from './shared/state/AuthUserState';
import {hasPermission} from 'jugacu-lib';

@Injectable({
  providedIn: 'root'
})
export class CreateGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router
  ) {
  }

   canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.store.selectSnapshot(AuthUserState.user);
    const canEnter = hasPermission('create posts', user);

    if (!canEnter) {
      this.router.navigate(['/']);
    }

    return canEnter;
  }

}
