import {User} from 'jugacu-lib';

export default class UpdateSettings {
  static readonly type = '[User] UpdateSettings';

  public constructor(
    public token: string,
    public user: User
  ) {
  }
}
