<div *ngIf="open" class="modal-wrapper">
  <div class="modal">
    <app-loading [loading]="false" #loadingComponent size="small"></app-loading>
    <div class="container" *ngIf="error">
      {{error}}
      <div class="buttons">
        <button class="cancel" (click)="close()">Cancelar</button>
      </div>
    </div>
    <div class="container" *ngIf="!loadingComponent.isActive() && !error">
      <h2>¿Estás seguro?</h2>
      <p>
        Los datos se perderán para siempre.
      </p>
      <div class="buttons">
        <button class="cancel" (click)="close()">Cancelar</button>
        <button class="delete" (click)="submit()">Borrar</button>
      </div>
    </div>
  </div>
</div>
