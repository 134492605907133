import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {AuthService} from '../../auth.service';
import {hasPermission} from 'jugacu-lib';
import {LoadingComponent} from '../loading/loading.component';
import {AuthState} from '../../shared/state/AuthState';
import {Store} from '@ngxs/store';
import {Post} from 'jugacu-lib';
import {User} from 'jugacu-lib';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {

  @ViewChild('loadingComponent') loading: LoadingComponent;

  @ViewChild('deleteModalComponent') deleteModalComponent: DeleteModalComponent;
  @ViewChild('backgroundElement') backgroundElement: ElementRef;

  @Output() deleted: EventEmitter<Post> = new EventEmitter();

  open: boolean;
  protected post: Post;
  protected user: User;
  protected error: string;

  constructor(
    private authService: AuthService,
    private store: Store
  ) {
  }

  ngOnInit(): void {
  }

  public async delete(post: Post, user: User): Promise<void> {
    this.post = post;
    this.error = '';
    this.open = (hasPermission('delete own posts', user) && post.author.name === user.name) || hasPermission('delete any post', user);
  }

  public close() {
    this.open = false;
  }

  async submit() {
    this.loading.enable();

    try {
      const token = this.store.selectSnapshot(AuthState.token);
      await this.authService.delete(token, this.post);
      this.deleted.emit(this.post);
      this.close();
    } catch (e) {
      this.error = (e.error && e.error.message) ? e.error.message : 'Server error.';
    } finally {
      this.loading.disable();
    }

  }

}
